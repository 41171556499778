import { InlineWidget } from "react-calendly";
import Header from "./Header";

const DemoPage = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <Header />
      <div className="mx-auto max-w-6xl px-6 text-center">
        <h2 className="text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">
          Schedule Your Metro Demo Today
        </h2>
        <p className="mt-4 text-lg text-slate-600">
          Discover how Metro can streamline your patient intake process. Book a
          30-minute demo and see Metro in action.
        </p>
        <div className="mt-10">
          <InlineWidget
            styles={{ height: "800px" }}
            url="https://calendly.com/metrohq/30min"
          />
        </div>
      </div>
    </div>
  );
};

export default DemoPage;
