const faqs = [
  {
    id: 1,
    question: "How does Metro ensure HIPAA compliance?",
    answer:
      "Metro prioritizes data security with HIPAA-compliant practices, including end-to-end encryption and secure data storage. We also include a BAA for all our clients.",
  },
  {
    id: 2,
    question: "Will Metro sign a Business Associate Agreement (BAA)?",
    answer:
      "Yes, Metro understands the importance of HIPAA compliance for healthcare providers. We are willing to sign a Business Associate Agreement (BAA) with our clients to ensure full compliance with HIPAA regulations and safeguard patient health information (PHI).",
  },
  {
    id: 3,
    question: "What kind of support does Metro offer?",
    answer:
      "Metro provides comprehensive support through email and a dedicated client portal. Our team is available to assist with any questions or issues you may encounter.",
  },
  {
    id: 4,
    question: "Can I customize forms to match my clinic's branding?",
    answer:
      "Absolutely! Metro's form builder allows you to customize forms with your clinic's logo, colors, and more, ensuring a consistent patient experience.",
  },
  {
    id: 5,
    question: "How does Metro handle data security?",
    answer:
      "We employ advanced security measures, including SSL encryption, regular security audits, and compliance checks, to protect your patients' information.",
  },
  {
    id: 6,
    question: "How can Metro help reduce patient no-shows?",
    answer:
      "Metro's automated email and SMS reminders ensure your patients remember their appointments, reducing no-shows and helping your clinic run smoothly.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div
        id="faq"
        className="mx-auto max-w-7xl divide-y divide-slate-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40"
      >
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-slate-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-slate-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-slate-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-slate-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
