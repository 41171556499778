import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Hero from "./components/Hero";
import FeaturesOne from "./components/FeaturesOne";
import FeaturesTwo from "./components/FeaturesTwo";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
import DemoPage from "./components/DemoPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import FAQ from "./components/FAQ";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <Hero />
          <FeaturesOne />
          <FeaturesTwo />
          <CTA />
          <FAQ />
          <Footer />
        </div>
      ),
    },
    {
      path: "/demo",
      element: <DemoPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
