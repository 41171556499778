import React from "react";
import Header from "./Header";

const TermsOfService = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:py-28 lg:px-8">
      <Header />
      <h1 className="text-center text-3xl font-bold text-slate-900">
        Terms of Service
      </h1>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">Introduction</h2>
        <p className="text-base text-slate-600">
          Welcome to Metro. These Terms of Service ("Terms") govern your use of
          the services provided by Metro ("we", "us", "our"), including our
          website and our HIPAA-compliant form builder (collectively, the
          "Services"). By using our Services, you agree to these Terms.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">
          The Metro Service
        </h2>
        <p className="text-base text-slate-600">
          Metro provides a HIPAA-compliant form builder designed for marketing
          agencies and healthcare clients. It ensures secure, compliant data
          handling and tracking. The Services allow you to build and publish
          forms to collect patient data in a manner that meets HIPAA's privacy
          requirements.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">Privacy</h2>
        <p className="text-base text-slate-600">
          Your privacy is important to us. Our Privacy Policy explains how we
          collect, use, and share your information. By using our Services, you
          agree that we can use your data in accordance with our Privacy Policy.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">
          Use of the Service
        </h2>
        <p className="text-base text-slate-600">
          You must follow any guidelines or policies associated with the
          Services. You may not misuse our Services, use them in a way that
          violates the law, or interfere with their normal operation.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">Your Account</h2>
        <p className="text-base text-slate-600">
          You need to create an account to use our Services. You are responsible
          for safeguarding your account and for any activities that occur under
          your account. You should report to us immediately if you suspect any
          unauthorized use of your account.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">Modifications</h2>
        <p className="text-base text-slate-600">
          We may modify these Terms at any time. If we do, we will let you know
          either by posting the modified Terms on the Site or through other
          communications. By continuing to use our Services after the
          modifications have become effective, you are agreeing to be bound by
          the modified Terms.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">Termination</h2>
        <p className="text-base text-slate-600">
          We may terminate or suspend your access to our Services at any time,
          without prior notice, for any reason that we determine in our sole
          discretion.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">
          Dispute Resolution
        </h2>
        <p className="text-base text-slate-600">
          Any disputes arising out of or relating to these Terms or the Services
          will be resolved in accordance with the laws of the jurisdiction in
          which our company is registered.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg font-medium text-slate-900">
          Limitation of Liability
        </h2>
        <p className="text-base text-slate-600">
          To the maximum extent permitted by law, Metro shall not be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses resulting from your use of our Services.
        </p>
      </section>

      <section className="mt-6 mb-12">
        <h2 className="text-lg font-medium text-slate-900">Contact Us</h2>
        <p className="text-base text-slate-600">
          If you have any questions about these Terms, please contact us at
          adam@getmetro.io.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
