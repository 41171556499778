import {
  DocumentTextIcon,
  ShieldExclamationIcon,
  PencilIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Signed BAA Included",
    description:
      "Guarantee compliance with a Business Associate Agreement as part of our commitment to HIPAA.",
    icon: DocumentTextIcon,
  },
  {
    name: "Signature Fields",
    description:
      "Easily collect e-signatures directly on forms for efficient patient processing.",
    icon: PencilIcon,
  },
  {
    name: "SMS Invitations",
    description:
      "Send direct SMS invitations to patients for form completion, increasing response rates.",
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: "Advanced Security Measures",
    description:
      "Protect patient data with end-to-end encryption, ensuring the highest level of security.",
    icon: ShieldExclamationIcon,
  },
];

export default function FeaturesTwo() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div id="featuresTwo" className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-sky-600">
              Comprehensive features
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">
              Beyond the basics
            </p>
            <p className="mt-6 text-base leading-7 text-slate-600">
              Enhance patient intake with Metro’s additional features designed
              for security and convenience.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-slate-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-slate-900">
                  <feature.icon
                    className="absolute left-0 top-1 h-5 w-5 text-sky-500"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
