import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:py-28 lg:px-8">
      <Header />
      <h1 className="text-center text-3xl leading-9 font-extrabold text-slate-900 sm:text-4xl sm:leading-10">
        Privacy Policy
      </h1>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Introduction
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          Your privacy is important to us. This Privacy Policy explains how we
          collect, store, use, and disclose your information when you use Metro.
          By using Metro, you agree to the collection and use of information in
          accordance with this policy.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          User Consent
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          By using our services, you consent to the data practices outlined in
          this privacy policy, including the collection, use, and sharing of
          your data with third-party services we may integrate with, for the
          purposes described herein.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Use of Information
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          We use the information we collect to provide, improve, and develop
          Metro, to create and maintain a trusted and safer environment and
          comply with our legal obligations. We may also use third-party
          analytics providers to obtain, compile and analyze information about
          how users are using and interacting with Metro. Our services utilize
          third-party APIs to provide certain functionalities. These third-party
          services may collect, store, and process your data in accordance with
          their own privacy policies. By using our service, you agree to the
          data practices as described in this policy and any data processing
          carried out by these third-party services.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Sharing & Disclosure{" "}
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          Metro respects your privacy. We do not disclose your personal
          information to third parties without your consent, other than to our
          employees, contractors and affiliated organizations that need to know
          that information in order to process it on Metro's behalf or to
          provide services available at Metro.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Data Retention
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          Data related to third-party integrations is retained in accordance
          with our data retention policy. Any data no longer required for the
          purposes outlined in this policy will be anonymized or deleted in a
          timely manner.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Security
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          We are continuously implementing and updating administrative,
          technical, and physical security measures to help protect your
          information against unauthorized access, loss, destruction, or
          alteration.
        </p>
      </section>

      <section className="mt-6 mb-12">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Cookies
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          A cookie is a string of information that a website stores on a
          visitor's computer, and that the visitor's browser provides to the
          Service each time the visitor returns. Metro uses cookies to help
          Metro identify and track visitors, their usage of Metro Service, and
          their Service access preferences.
        </p>
      </section>

      <section className="mt-6 mb-12">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Changes to this Privacy Policy
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          We may change this Privacy Policy from time to time. If we make
          significant changes, we will notify you of the changes through the
          Metro platform or through others means, such as email.
        </p>
      </section>

      <section className="mt-6 mb-12">
        <h2 className="text-lg leading-6 font-medium text-slate-900">
          Contact Us
        </h2>
        <p className="mt-4 text-base leading-6 text-slate-600">
          If you have any questions about this Privacy Policy, please contact us
          at adam@getmetro.io.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
